import { FormAnalyticsData, formEvent } from "@shared/services/analytics-service";
import { Button, ButtonProps } from "@ui/components/button/button";
import { MouseEventHandler, memo, useCallback, useEffect, useRef } from "react";

export interface SubmitButtonProps extends ButtonProps {
    analyticsData?: FormAnalyticsData<any>;
}

export const SubmitButton = memo<SubmitButtonProps>(
    ({ analyticsData, className, large = true, onClick, type = "submit", ...restProps }) => {
        const innerRef = useRef<HTMLDivElement>(null);

        const _onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
            (e) => {
                if (analyticsData) {
                    formEvent(analyticsData.category, analyticsData.action, analyticsData.labels.submit)();
                }
                if (onClick) {
                    onClick(e);
                }
            },
            [analyticsData, onClick],
        );

        useEffect(() => {
            const node = innerRef.current;
            if (node) {
                const observer = new IntersectionObserver(
                    ([e]) => e.target.classList.toggle("is-sticky", e.intersectionRatio < 1),
                    { rootMargin: "0px 0px -1px 0px", threshold: [1] },
                );
                observer.observe(node);
            }
        }, []);

        return (
            <div className={`_form-field _form-field--submit ${className || ""}`} ref={innerRef}>
                <Button large={large} onClick={_onClick} type={type} {...restProps} />
            </div>
        );
    },
);

SubmitButton.displayName = "SubmitButton";
