import { FormAnalyticsData, formEvent } from "@shared/services/analytics-service";
import { Validator } from "@shared/utils/validator/validator";
import { TextInput as UITextInput, TextInputProps as UITextInputProps } from "@ui/components/input/text-input";
import { FocusEventHandler, useCallback } from "react";
import { UseControllerProps, useController } from "react-hook-form";

export interface TextInputProps<FormData extends Record<string, any>>
    extends Omit<
            UITextInputProps,
            "id" | "invalid" | "max" | "min" | "name" | "onChange" | "pattern" | "step" | "value"
        >,
        UseControllerProps<FormData> {
    analyticsData?: FormAnalyticsData<FormData>;
    form: string;
    requiredMessage?: string;
    type?: "email" | "password" | "tel" | "text" | "url";
}

export const TextInput = <FormData extends Record<string, any>>(props: TextInputProps<FormData>) => {
    const {
        analyticsData,
        control,
        form,
        name,
        onBlur,
        required,
        requiredMessage,
        rules = {},
        shouldUnregister,
        type = "text",
        ...restProps
    } = props;

    const { field, fieldState } = useController<FormData>({
        control,
        name,
        rules: {
            required: required ? requiredMessage || "Toto pole je povinné" : undefined,
            pattern:
                type === "email"
                    ? {
                          value: Validator.EMAIL_REGEXP,
                          message: "E-mail by měl být ve\xa0formátu: info@email.cz",
                      }
                    : type === "tel"
                      ? {
                            value: Validator.PHONE_REGEXP,
                            message: "Zadaný telefon není ve správném formátu",
                        }
                      : undefined,
            validate: (v) => (Validator.isOnlySpaces(v) ? "Vyplňte platnou hodnotu." : undefined),
            ...rules,
        },
        shouldUnregister,
    });

    const _onBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            if (analyticsData && analyticsData.labels[name]) {
                formEvent(analyticsData.category, analyticsData.action, analyticsData.labels[name], field.value)();
            }

            field.onBlur();
            if (onBlur) {
                onBlur(e);
            }
        },
        [analyticsData, field, name, onBlur],
    );

    const inputId = `${form ? form + "__" : ""}${name}`;

    return (
        <UITextInput
            error={fieldState.error?.message}
            form={form}
            id={inputId}
            invalid={fieldState.invalid}
            name={field.name}
            onBlur={_onBlur}
            onChange={field.onChange}
            ref={field.ref}
            required={required}
            type={type}
            value={field.value || ""}
            {...restProps}
        />
    );
};
