import { config } from "@config/config";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import Head from "next/head";
import { memo } from "react";

export const Prefetch = memo(() => (
    <Head>
        {(config.GA_ID || config.GTM_ID) && (
            <>
                <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
                <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
            </>
        )}
        {/*<link rel="dns-prefetch preconnect" href="https://connect.facebook.net" />
            <link rel="dns-prefetch preconnect" href="https://www.google.com" />
            <link rel="dns-prefetch preconnect" href="https://www.facebook.com" />*/}
        {/*<link
            as="font"
            crossOrigin="anonymous"
            href="/static/fonts/inter/inter-v7-latin-ext_latin-regular.woff2"
            rel="preload"
        />*/}
        <link as="image" crossOrigin="anonymous" href={`${ICON_SPRITE}?v=${ICONS_VERSION}`} rel="prefetch" />
    </Head>
));

Prefetch.displayName = "Prefetch";
