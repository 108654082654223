import { config } from "@config/config";

// TODO: doimplementovat co bude potřeba

export interface EventData {
    event: "InteractionUI" | "InteractionForm";
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
    eventValue?: string;
}
type ConsentType = "granted" | "denied";

export interface GtmConsentData {
    event: "consentInit" | "consentUpdate";
    "dl.analytics_storage": ConsentType;
    "dl.ad_storage": ConsentType;
    "dl.personalization_storage": ConsentType;
}

type GtmEventData = EventData;

interface GtmDataLayer {
    push: (gtmEventData: GtmEventData | GtmConsentData) => void;
}

declare global {
    interface Window {
        dataLayer: GtmDataLayer;
    }
}

export interface FormAnalyticsData<FormData> {
    category: string;
    action: string;
    labels: Record<keyof FormData, string>;
    submit: string;
}

const gtmEvent = (gtmEventData: GtmEventData) => {
    if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push(gtmEventData);
    }
};

const event = (eventData: EventData): void => {
    if (eventData.eventAction) {
        if (config.STAGE !== "prod") {
            // eslint-disable-next-line no-console
            console.log("DEBUG ANALYTICS EVENT DATA: ", eventData);
        }
        gtmEvent(eventData);
    } else {
        if (config.STAGE !== "prod") {
            // eslint-disable-next-line no-console
            console.log("DEBUG ANALYTICS - NOT LOGGED");
        }
    }
};

export const formEvent =
    (
        category: EventData["eventCategory"],
        action: EventData["eventAction"],
        label: EventData["eventLabel"],
        value?: EventData["eventValue"],
    ) =>
    () =>
        event({
            event: "InteractionForm",
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value,
        });

export const uiEvent =
    (
        category: EventData["eventCategory"],
        action: EventData["eventAction"],
        label: EventData["eventLabel"],
        value?: EventData["eventValue"],
    ) =>
    () =>
        event({
            event: "InteractionUI",
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value,
        });

export const AnalyticsService = {
    event,
    uiEvent,
    formEvent,
};
