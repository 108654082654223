import { THEME, Themes } from "@ui/styles/theme";
import Head from "next/head";
import React, { memo } from "react";

interface Props {
    variant: Themes;
}

export const BodyBackground = memo<Props>(({ variant }) => (
    <Head>
        <style>{`:root { --page-bg: ${THEME[variant].background}; } body { background-color: var(--page-bg); }`}</style>
        <meta name="theme-color" content={THEME[variant].background} />
    </Head>
));

BodyBackground.displayName = "BodyBackground";
