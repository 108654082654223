import { ApolloError } from "@apollo/client";
import { captureException } from "@sentry/nextjs";
import { errorMessage } from "@shared/services/flash-messages-service";
import { t } from "@shared/services/translation-service";

function getGraphQLErrorMessage(e: ApolloError): string {
    if (e.networkError) {
        return t("error.network");
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (e.graphQLErrors[0].extensions?.code === "PUBLIC_MESSAGE_ERROR") {
        return e.graphQLErrors[0].message;
    }
    const errorMessageName = e.graphQLErrors[0].message;
    const title = t(errorMessageName as any);
    if (title === errorMessageName) {
        return "";
    }
    return title;
}

function getErrorMessage(e: any): string {
    if (e instanceof ApolloError) {
        return getGraphQLErrorMessage(e);
    }
    return "";
}

function logError(e: any): void {
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        captureException(e);
    } else {
        console.warn("Error handler: ", e); // eslint-disable-line no-console
    }
}

function handleError(e: any, showFlashMessage = true) {
    const message = getErrorMessage(e);

    if (!message) {
        logError(e);
    }

    if (showFlashMessage) {
        errorMessage(message || t("error.unknown"));
    }
}

export const ErrorService = {
    handleError,
};
