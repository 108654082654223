import { useFixAndroidOnFocus } from "@ui/hooks/use-fix-android-on-focus";
import { useInputSubmit } from "@ui/hooks/use-input-submit";
import { CLASSES } from "@uxf/core/constants/classes";
import { useInputFocus } from "@uxf/core/hooks/useInputFocus";
import { composeRefs } from "@uxf/core/utils/composeRefs";
import { cx } from "@uxf/core/utils/cx";
import { ChangeEventHandler, CSSProperties, FocusEventHandler, forwardRef, ReactNode, useRef } from "react";
import { ErrorMessage } from "./error-mesage";
import { Label } from "./label";

export interface TextInputProps {
    autoComplete?: string;
    className?: string;
    disabled?: boolean;
    enterKeyHint?: "enter" | "done" | "go" | "next" | "previous" | "search" | "send" | undefined;
    error?: string;
    form?: string;
    id: string;
    inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
    invalid?: boolean;
    label: string;
    name?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    rightElement?: ReactNode;
    style?: CSSProperties;
    subtitle?: string;
    type?: "email" | "number" | "password" | "tel" | "text" | "url";
    value?: number | string | null;

    // type="password|search|tel|text|url"
    maxLength?: number | undefined;
    minLength?: number | undefined;

    // type="number"
    max?: number | string;
    min?: number | string;
    pattern?: string;
    step?: number | string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            autoComplete,
            className,
            disabled,
            enterKeyHint,
            error,
            form,
            id,
            inputMode,
            invalid,
            label,
            max,
            maxLength,
            min,
            minLength,
            name,
            onBlur,
            onChange,
            onFocus,
            pattern,
            placeholder,
            readOnly,
            required,
            rightElement,
            step,
            style,
            subtitle,
            type = "text",
            value,
        },
        ref,
    ) => {
        const innerRef = useRef<HTMLInputElement>(null);

        const input = useInputFocus(innerRef, onBlur, onFocus);
        const fixedOnFocus = useFixAndroidOnFocus(input.onFocus);
        useInputSubmit(innerRef, "text-input", disabled);

        const errorId = invalid && id ? `${id}--errormessage` : undefined;

        return (
            <div className={`_form-field ${className || ""}`} style={style}>
                <Label htmlFor={id} subtitle={subtitle}>
                    {label}
                </Label>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className={cx(
                        "_text-input__control",
                        input.focused && CLASSES.IS_FOCUSED,
                        disabled && CLASSES.IS_DISABLED,
                        readOnly && CLASSES.IS_READONLY,
                        invalid && CLASSES.IS_INVALID,
                    )}
                    onClick={!disabled ? input.focus : undefined}
                >
                    <input
                        aria-describedby={errorId}
                        aria-invalid={invalid}
                        autoComplete={autoComplete}
                        className="input-reset _text-input"
                        disabled={disabled}
                        enterKeyHint={enterKeyHint}
                        form={form}
                        id={id}
                        inputMode={inputMode}
                        lang="cs-CZ"
                        max={max}
                        maxLength={maxLength}
                        min={min}
                        minLength={minLength}
                        name={name}
                        onBlur={input.onBlur}
                        onChange={onChange}
                        onFocus={fixedOnFocus}
                        pattern={pattern}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        ref={composeRefs(innerRef, ref)}
                        required={required}
                        step={step}
                        type={type}
                        value={value ?? undefined}
                    />
                    {rightElement}
                </div>
                {invalid && error && <ErrorMessage id={errorId}>{error}</ErrorMessage>}
            </div>
        );
    },
);

TextInput.displayName = "TextInput";
