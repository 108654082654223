import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import { cx } from "@uxf/core/utils/cx";
import { AnchorHTMLAttributes, ForwardRefRenderFunction, forwardRef, memo } from "react";

const VARIANTS = {
    default: "_text-link--default",
    primary: "_text-link--primary",
    secondary: "_text-link--secondary",
    white: "_text-link--white",
    danger: "_text-link--danger",
    success: "_text-link--success",
} as const;

export interface TextLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type">, UseAnchorProps {
    variant?: keyof typeof VARIANTS;
}

const Component: ForwardRefRenderFunction<HTMLAnchorElement, TextLinkProps> = (
    { children, className, variant = "default", ...restProps },
    ref,
) => {
    const anchorProps = useAnchorProps<AnchorHTMLAttributes<HTMLAnchorElement>>({
        className: cx("_text-link", VARIANTS[variant], className),
        ...restProps,
    });

    return (
        <a ref={ref} {...anchorProps}>
            {children}
        </a>
    );
};

export const TextLink = memo(forwardRef(Component));

TextLink.displayName = "TextLink";
