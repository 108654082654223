import { forwardRef, LabelHTMLAttributes } from "react";

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    dense?: boolean;
    subtitle?: string;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
    ({ children, className, dense, subtitle, ...restProps }, ref) => (
        <label className={`ui-typo-body block font-bold ${className || ""}`} ref={ref} {...restProps}>
            {subtitle ? (
                <>
                    <span className="block">
                        {children}
                        <br className="sr-only" />
                    </span>
                    <span className={`block font-normal text-gray-muted ${dense ? "mt-1" : "mt-2"}`}>{subtitle}</span>
                </>
            ) : (
                children
            )}
        </label>
    ),
);

Label.displayName = "Label";
