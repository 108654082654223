import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import { cx } from "@uxf/core/utils/cx";
import { AnchorHTMLAttributes, ForwardRefRenderFunction, forwardRef, memo } from "react";

const VARIANTS = {
    primary: "_buttonish--primary",
    outlined: "_buttonish--outlined",
    text: "_buttonish--text",
    textWhite: "_buttonish--text-white",
    danger: "_buttonish--danger",
    success: "_buttonish--success",
} as const;

export interface ButtonProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type">, UseAnchorProps {
    large?: boolean;
    variant?: keyof typeof VARIANTS;
}

const Component: ForwardRefRenderFunction<HTMLAnchorElement, ButtonProps> = (
    { children, className, large, variant = "primary", ...restProps },
    ref,
) => {
    const anchorProps = useAnchorProps<AnchorHTMLAttributes<HTMLAnchorElement>>({
        className: cx("_buttonish _button", VARIANTS[variant], large ? "_button--64" : "_button--40", className),
        ...restProps,
    });

    return (
        <a ref={ref} {...anchorProps}>
            {typeof children === "string" ? <span className="truncate">{children}</span> : children}
        </a>
    );
};

export const Button = memo(forwardRef(Component));

Button.displayName = "Button";
