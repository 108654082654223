import { DateHelper } from "@shared/utils/date-helper/date-helper";

const EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@À-ÖÙ-öù-ÿĀ-žḀ-ỿ"]+(\.[^<>()[\]\\.,;:\s@À-ÖÙ-öù-ÿĀ-žḀ-ỿ"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const PHONE_REGEXP = /^(\+420\s?)?[1-9]\d{2}\s?\d{3}\s?\d{3}$/;
const PHONE_REGEXP = /^(\+)?[\d\s]*$/;

function emptyValue(value: unknown) {
    return value === null || value === undefined || (typeof value === "string" && value.trim() === "");
}

function isOnlySpaces(value: unknown) {
    return typeof value === "string" && value.length > 0 && value.trim() === "";
}

const isNotAfterDate =
    (maxDate: Date, errorMessage = `Zadané datum nesmí být později než ${maxDate.toLocaleDateString("cs-CZ")}`) =>
    (value: any) => {
        if (value) {
            const date = new Date(value);
            return isNaN(date.valueOf()) || date > maxDate ? errorMessage : undefined;
        }
        return undefined;
    };

const isNotBeforeDate =
    (minDate: Date, errorMessage = `Zadané datum nesmí být dříve než ${minDate.toLocaleDateString("cs-CZ")}`) =>
    (value: any) => {
        if (value) {
            const date = new Date(value);
            return isNaN(date.valueOf()) || date < minDate ? errorMessage : undefined;
        }
        return undefined;
    };

const isNotBeforeToday =
    (errorMessage = "Zadané datum nesmí být v minulosti") =>
    (value: any) => {
        if (value) {
            const date = new Date(value);
            return DateHelper.isBeforeToday(date) ? errorMessage : undefined;
        }
        return undefined;
    };

const isNotFutureDate =
    (errorMessage = "Zadané datum nesmí být v budoucnosti") =>
    (value: any) => {
        if (value) {
            const date = new Date(value);
            return isNaN(date.valueOf()) || date > new Date() ? errorMessage : undefined;
        }
        return undefined;
    };

export const Validator = {
    EMAIL_REGEXP,
    PHONE_REGEXP,
    emptyValue,
    isOnlySpaces,
    isNotAfterDate,
    isNotBeforeDate,
    isNotBeforeToday,
    isNotFutureDate,
};
