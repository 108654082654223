import dayjs, { ConfigType, extend } from "dayjs";
import IsToday from "dayjs/plugin/isToday";

extend(IsToday);

const isSameDate = (date1: ConfigType, date2: ConfigType): boolean => {
    return !!date1 && !!date2 && dayjs(date1).isSame(dayjs(date2));
};

const isAfterToday = (dateToCheck: ConfigType): boolean => {
    return !!dateToCheck && dayjs(dateToCheck).isAfter(dayjs());
};

const isToday = (date: ConfigType): boolean => {
    if (!date) {
        return false;
    }

    return dayjs(date).isToday();
};

const isBeforeNow = (dateToCheck: ConfigType): boolean => {
    return !!dateToCheck && dayjs(dateToCheck).isBefore(dayjs());
};

const isBeforeToday = (dateToCheck: ConfigType): boolean => {
    return !!dateToCheck && dayjs(dateToCheck).startOf("day").isBefore(dayjs().startOf("day"));
};

const isBeforeDate = (firstDate: ConfigType, secondDate: ConfigType): boolean => {
    return !!firstDate && !!secondDate && dayjs(secondDate).startOf("day").isBefore(dayjs(firstDate).startOf("day"));
};

const DATE_FORMAT = "YYYY-MM-DD";
const MONTH_FORMAT = "YYYY-MM";
const TIME_FORMAT = "HH:mm";
const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const DateHelper = {
    isAfterToday,
    isBeforeNow,
    isBeforeToday,
    isSameDate,
    isToday,
    isBeforeDate,
    DATE_FORMAT,
    MONTH_FORMAT,
    TIME_FORMAT,
    DATE_TIME_FORMAT,
};
