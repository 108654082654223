/* eslint-disable */
/* tslint:disable */
/* prettier-ignore */
export const translations = {
    "error.network": {
        "cs": "Server není dostupný"
    },
    "error.unknown": {
        "cs": "Neznámá chyba.",
    },
    ["Invalid credentials"]: {
        "cs": "E-mail a\xa0heslo nesedí. Zkontroluj, jestli vyplňuješ správný e-mail a\xa0heslo."
    },
    "Invalid password": {
        "cs": "Neplatné přihlašovací údaje"
    },
    "User not found": {
        "cs": "Neplatné přihlašovací údaje"
    },

    "unresolvedPrizes.format.count": {
        "cs": {
            "zero": "Všechny odměny jsou již vydané",
            "one": "Je třeba označit odměnu jako vydanou nebo ji skrýt.",
            "few": "Je třeba označit {{count}} odměny jako vydané nebo je skrýt.",
            "other": "Je třeba označit {{count}} odměn jako vydané nebo je skrýt."
        },
        "en": ""
    },
};
