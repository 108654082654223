import { TextLink } from "@ui/components/text-link/text-link";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

interface Props extends HTMLAttributes<HTMLParagraphElement> {
    onRetry?: () => void;
}

export const ErrorMessage: FC<PropsWithChildren<Props>> = ({ children, className, onRetry, ...restProps }) => (
    <p className={`ui-typo-body mt-1 text-right text-danger ${className || ""}`} {...restProps}>
        {children}
        {onRetry && (
            <>
                {" "}
                <TextLink className="font-semibold underline" onClick={onRetry} variant="danger">
                    Zkusit znovu
                </TextLink>
            </>
        )}
    </p>
);
