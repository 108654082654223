import { useLatest } from "@ui/hooks/use-latest";
import { RefObject, useEffect } from "react";

function childrenHasFocus(node: HTMLElement) {
    node.childNodes.forEach((child) => {
        if (document.activeElement === child) {
            return true;
        }
    });
    return false;
}

type SubmitResolverType = "checkbox" | "radio-group" | "text-input";

function submitResolver(type: SubmitResolverType, node: HTMLElement): boolean {
    switch (type) {
        case "checkbox":
            return document.activeElement === node.firstChild;
        case "radio-group":
            return childrenHasFocus(node);
        case "text-input":
            return document.activeElement === node;
    }
}

export function useInputSubmit<T extends HTMLElement>(
    ref: RefObject<T>,
    type: SubmitResolverType,
    disabled: boolean | undefined,
) {
    const latestType = useLatest(type);

    useEffect(() => {
        const node = ref.current;
        if (!node || disabled) {
            return;
        }
        const handleSubmit: EventListener = (e) => {
            if (e instanceof KeyboardEvent && e.key === "Enter" && submitResolver(latestType.current, node)) {
                const closestForm = document.activeElement?.closest("form");
                if (closestForm) {
                    closestForm.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
                }
            }
        };
        node.addEventListener("keydown", handleSubmit);
        return () => node.removeEventListener("keydown", handleSubmit);
    }, [disabled, latestType, ref]);
}
