import jump from "jump.js";
import { FocusEventHandler, useCallback } from "react";
import UAParser from "ua-parser-js";

export function useFixAndroidOnFocus<T extends HTMLElement>(onFocus?: FocusEventHandler<T>) {
    return useCallback<FocusEventHandler<T>>(
        (e) => {
            const ua = typeof navigator !== "undefined" && navigator.userAgent;
            if (ua) {
                const uaParser = new UAParser(ua);
                if (uaParser.getOS().name === "Android" && uaParser.getEngine().name === "Blink") {
                    const target = e.target;
                    const rect = target.getBoundingClientRect();
                    if (
                        rect.top < 60 ||
                        (window.visualViewport !== null && window.visualViewport.height - rect.bottom < 140)
                    ) {
                        jump(rect.y, {
                            duration: 0,
                            offset: -60,
                        });
                    }
                }
            }
            if (onFocus) {
                onFocus(e);
            }
        },
        [onFocus],
    );
}
